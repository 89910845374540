import React, { useState } from "react";
import "./Portfolio.css";
import { Button } from "../ButtonElement";
import { Link } from "react-router-dom";
import PortfolioItem from "./PortfolioItem";
import projects from "./Projects.js";
import { IconButton, Slide } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const Portfolio = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextProject = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % projects.length);
  };

  // Function to go to the previous project
  const prevProject = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + projects.length) % projects.length
    );
  };
  return (
    <div className="portfolio-div" id="portfolio">
      <div style={{ display: "flex", flexDirection: "column", gap: ".5rem" }}>
        <span className="portfolio-heading">
          The site you're viewing was built from scratch.
        </span>
        <span>
          With a diverse portfolio spanning various industries, we take pride in
          helping our clients establish a strong online presence, enhance
          customer engagement, and drive business growth.
        </span>
      </div>
      <div className="projects-mobile">
        <Slide direction="left" in={true} mountOnEnter unmountOnExit>
          <div>
            <PortfolioItem project={projects[currentIndex]} />;
          </div>
        </Slide>
        <div className="proj-nav">
        <IconButton>
          {" "}
          <ArrowBackIcon onClick={prevProject} />
        </IconButton>
        <IconButton>
          {" "}
          <ArrowForwardIcon onClick={nextProject} />
        </IconButton>
      </div>
      </div>
     
      <div className="projects-wide">
        {projects.map((project) => {
          return <PortfolioItem project={project} />;
        })}
      </div>
      {/* <Link>
        {" "}
        <Button primary={true}>View Portfolio</Button>
      </Link> */}
    </div>
  );
};

export default Portfolio;
