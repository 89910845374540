import styled from "styled-components";

import { MdArrowForward, MdKeyboardArrowRight } from "react-icons/md";

export const HeroContainer = styled.div`
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 800px;
  position: relative;
  z-index: 1;

  :before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.9) 0%,
        rgba(0, 0, 0, 0.4) 100%
      ),
      linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
    z-index: 2;
  }
`;

export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  & video {
    width: 100vw;
    height: 100vh;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
  }
`;

export const HeroImage = styled.img`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
`;

export const HeroContent = styled.div`
  max-width: 1500px;
  z-index: 3;
  padding: 45px 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  position: relative;
  top: -35px;

  @media screen and (max-width: 780px) {
    align-items: center;
  }
`;

export const HeroH1 = styled.h1`
  color: #fff;
  font-size: 80px;
  text-align: left;
  max-width: 900px;
  font-family: "Hind";
  line-height: 1.2;

  & pink {
    color: #f900bf;
  }

  @media screen and (max-width: 780px) {
    font-size: 65px;
    text-align: center;
  }

  @media screen and (max-width: 480px) {
    font-size: 44px;
  }
`;


export const HeroBtnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`;
