import React from "react";
import { Button } from "../ButtonElement";
import "./PortfolioItem.css";

const PortfolioItem = ({ project }) => {
  const handleClick = () => {
    window.open(project.link, "_blank");
  };
  return (
    <div className="portfolio-item">
      <div className="proj-img">
        <img src={project.img} alt="project-img" />
        </div>
      <div className="item-header">
        <span className="item-h1">{project.name}</span>
        <span>{project.description}</span>
        <Button primary={true} onClick={handleClick}>
        Visit Live Site
      </Button>
      </div>

    </div>
  );
};

export default PortfolioItem;
