import { Step, StepContent, StepLabel, Stepper } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./InfoTile.css";
import PersonIcon from "@mui/icons-material/Person";
import Delivarable from "./Delivarable";

const InfoTile = ({ section, idx }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [timerOn, setTimerOn] = useState(true);
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveStep((prevStep) => {
        // Cycle back to 0 when the last step is reached
        return (prevStep + 1) % (section.howItWorks?.length || 1);
      });
    }, 4000); // Increment every 2 seconds

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [section.howItWorks?.length]); // Dependency array includes length of steps
  return (
    <div className={`tile ${idx % 2 === 0 ? "primary" : "white"}`}>
      <div className="tile-heading">
        <span className="topline">{section.topLine}</span>
        <span className="headline">{section.headline}</span>
      </div>

      <span className="description">{section.description}</span>
      {/* <div className="key-item">
        <PersonIcon color="info" />
        <span>Client Action</span>
      </div> */}

      <div>
        <Stepper orientation="vertical">
          {section.howItWorks?.map((step, idx) => {
            return (
              <Step
                disabled={false}
                active={activeStep === idx}
                sx={{
                  "&:hover": { background: "var(--paper)" },
                  cursor: "pointer",
                }}
                onClick={() => {
                  setActiveStep(idx);
                }}
              >
                <StepLabel
                  slotProps={{
                    label: {
                      style: {
                        color: "white",
                        fontSize: "18px",
                        fontWeight: "bold",
                      },
                    },
                  }}
                >
                  {step.label}
                </StepLabel>
                <StepContent>
                  <div className="step-content">
                    <span> {step.description}</span>
                    {step.requirement && (
                      <div className="client-req">
                        <PersonIcon color="info" />
                        <span> {step.requirement}</span>
                      </div>
                    )}
                  </div>
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
      </div>
      <div className="deliverables-list">
        {section.deliverables?.map((d) => {
          return <Delivarable deliverable={d}/>
        })}
      </div>
    </div>
  );
};

export default InfoTile;
