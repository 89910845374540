import DevicesTwoToneIcon from "@mui/icons-material/DevicesTwoTone";
import DatasetLinkedIcon from "@mui/icons-material/DatasetLinked";
import PreviewIcon from "@mui/icons-material/Preview";
import ListAltTwoToneIcon from "@mui/icons-material/ListAltTwoTone";
import ErrorTwoToneIcon from "@mui/icons-material/ErrorTwoTone";

const howItWorks = [
  {
    label: "Getting Started",
    description:
      "Complete the New Client Form or schedule a consult to discuss your business needs.",
  },
  {
    label: "Get Your Quote",
    description:
      "You will receive a comprehensive proposal outlining the projected delivery date, estimated costs, and detailed website features tailored to your project requirements.",
    requirement: "Client must accept proposal within 14 days of receipt.",
  },
  {
    label: "Select Payment Method",
    description:
      "You may choose to pay in full or secure your project by making a non-refundable 30% deposit. The remaining balance will be due prior to project completion.",
  },
  {
    label: "Upload Branding and Media Assets",
    description:
      "Upon acceptance of our Terms of Service and the submission of all required documentation for your site, our engineering team will commence the design process.",
    requirement:
      "Clients must review and request modifications to the website design within 48 hours; the design becomes final upon acceptance.",
  },
  {
    label: "Access Your Client Dashboard",
    description:
      "Track project progress, preview your website, and approve deliverables or request modifications as needed.",
  },
  {
    label: "Project Completion",
    description: "We'll deliver your final site for final review.",
    requirement:
      "Clients must review and report problems within 24 hours; total balance due upon acceptance.",
  },
];

export const deliverables = [
  {
    icon: <DevicesTwoToneIcon color="primary" sx={{ fontSize: "75px" }} />,
    label: "Responsive Design",
  },
  {
    icon: <ListAltTwoToneIcon color="primary" sx={{ fontSize: "75px" }} />,
    label: "Maintenance Guide",
  },
  {
    icon: <ErrorTwoToneIcon color="primary" sx={{ fontSize: "75px" }} />,
    label: "Help Requests",
  },
];

export const info = [
  {
    topLine: "Trusted Partnership",
    headline: "A guaranteed hassle-free experience.",
    description:
      "Our streamlined approach simplifies complex tasks, making it easy for clients to understand their project’s progress and requirements. ",
    howItWorks: howItWorks,
  },
  {
    topLine: "Satisfaction Guranteed",
    headline: "A final product you will love.",
    description:
      "Our comprehensive support ensures that you have everything you need for a seamless experience.",
    deliverables: deliverables,
  },
  {
    topLine: "Secure Payments",
    headline: "Protection for you and your investment.",
    description:
      "When it comes to managing your payments, we don’t take shortcuts. That’s why we use Square, one of the most trusted payment platforms in the world. You’ll receive a detailed receipt for every payment, providing clear records for your business.",
  },
  {
    topLine: "SEO Optimized ",
    headline: "Market your product sooner than later",
    description:
      "Our final product will be SEO optimized, scalable, and user-friendly, ensuring excellent performance and a seamless experience for your audience.",
  },
];
