import styled from 'styled-components'
import { Link } from 'react-router-dom'


export const Button = styled.button`
    border-radius: 50px;
    background-image: ${({ primary }) => (primary ? '#F900BF' : '#fff')};
    background-image: ${({ primary }) => (primary ? 'linear-gradient(to right, #4700D8, #F900BF)' : '#fff')};
    white-space: nowrap;
    padding: ${({ big }) => (big ? '14px 48px' : '12px 30px')};
    color: #fff; 
    font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
    outline: none; 
    border: none; 
    display: flex; 
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    z-index: 1;
    font-family: 'Hind';

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({ primary }) => (primary ? '#fff' : '#F900BF')};
        color: #4700D8;
    }
`

export const SimpleBtn = styled.button`
color: #fff; 
border: none; 
background: none;
font-size: 24px;
font-family: 'Hind';
text-transform: uppercase;
max-width: 200px;

&:hover {
    color: #F900BF;
}
`