import React, { useState } from "react";
import HeroSection from "../components/HeroSection";
import InfoSection from "../components/InfoSection";

import Navbar from "../components/Navbar";
import Sidebar from "../components/Navbar/Sidebar";
import Services from "../components/Services";
import Icon1 from "../images/online-3.svg";
import Icon2 from "../images/love_mobile.svg";
import Icon3 from "../images/stripe_payments.svg";
import Icon4 from "../images/building_website.svg";
import AboutSection from "../components/AboutSection/index.js";
import Expertise from "../components/Expertise";
import Portfolio from "../components/PortfolioSection/Portfolio.js";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [active, setActive] = useState("Home");

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar
        isOpen={isOpen}
        toggle={toggle}
        active={active}
        setActive={setActive}
      />
      <Navbar toggle={toggle} active={active} setActive={setActive} />
      <HeroSection />
      {/* <Expertise /> */}
      <InfoSection />

      <Services />
      {/* <PortfolioSection /> */}
      <Portfolio />
      <AboutSection />
      {/* <ProjectView /> */}
    </>
  );
};

export default Home;
