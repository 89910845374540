import React, { useState } from "react";
import {
  HeroContainer,
  HeroBg,
  HeroImage,
  HeroContent,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
  HeroH1,
} from "./HeroElements";
import Image from "../../images/background.png";
import { Button } from "@mui/material";

const HeroSection = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  const scheduleConsultHandler = () => {
    console.log("button clicked");
    window.open(
      "https://calendly.com/mo-mixingmos/phone-consultation?month=2024-10"
    );
  };

  return (
    <HeroContainer id="home">
      <HeroBg>
        <HeroImage src={Image} />
      </HeroBg>
      <HeroContent>
        <HeroH1>
          Delivering the digital products <pink>you need.</pink>
        </HeroH1>
        <HeroBtnWrapper>
          <Button
          variant="contained"
            onClick={scheduleConsultHandler}
            onMouseEnter={onHover}
            onMouseLeave={onHover}
          >
            Let's Talk {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
