import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { Helmet } from "react-helmet";
import Home from "./pages";
import { createTheme, ThemeProvider } from "@mui/material";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#f800bf",
        contrastText: "#ffffff",
      },
      secondary: {
        main: "#4700d8",
        contrastText: "#79AC78",
      },
      text: {
        primary: "#000000",
      },
      error: {
        main: "#c21f3a",
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title> Mocreate | Engineering Consulting </title>
      </Helmet>
      <Router>
        <Home />
      </Router>
    </ThemeProvider>
  );
}

export default App;
