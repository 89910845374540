import mm from '../../images/bartending.jpg'
import sonshinedevelopments from '../../images/construction.jpg'
import parkerandsonsad from '../../images/detail.jpg'
const projects = [
  {
    name: "Mixing Mo's",
    description:
      "A pioneering mobile bartending company that embraces a digital approach to enhance customer convenience. With a custom-built website, clients can instantly calculate quotes and check availability in real-time, streamlining the booking process for events of all sizes.",
        link: "https://www.mixingmos.com/",
    img: mm
  },
  {
    name: "Sonshine Developments",
    description:
      "We crafted a strong brand and logo for the construction company that manages every aspect of home building and renovation. Our platform allows the company to streamline its hiring process by enabling potential employees to upload their resumes directly, making it easier to find qualified workers to join their team. This innovative approach enhances the company's recruitment efforts, ensuring they can effectively build a skilled workforce for their diverse projects.",
      link: "https://www.sonshinedevelopments.com/",
      img: sonshinedevelopments
  },
  {
    name: "Parker and Sons Auto Detail",
    description:
      "We developed a customized website for an auto detailing company that enhances their service offerings and simplifies online booking and payment processes. In addition to detailing services, the site features an integrated eCommerce platform for selling branded apparel, allowing customers to easily browse and purchase merchandise.",
      link: "https://www.parkerandsonsad.com/",
      img: parkerandsonsad
  },
];

export default projects;