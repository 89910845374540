import React from "react";
import { Button, Drawer, IconButton } from "@mui/material";
import { Link } from "react-scroll";
import "./Sidebar.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Sidebar = ({ toggle, isOpen, active, setActive }) => {
  const scheduleConsultHandler = () => {
    window.open(
      "https://calendly.com/mo-mixingmos/phone-consultation?month=2024-10"
    );
  };
  const sections = ["Home", "About", "Services", "Portfolio", "Contact"];
  return (
    <Drawer open={isOpen} anchor="left" onClose={toggle}>
      <div className="sidebar">
        <div className="bar-links">
          {sections.map((section) => {
            return (
              <Link
                activeClass="link active"
                to={section.toLocaleLowerCase()}
                spy={true}
                duration={300}
                smooth
                onClick={() => {
                  setActive(section);
                  toggle();
                }}
              >
                {section}
              </Link>
            );
          })}
        </div>
        <Button variant="contained" onClick={scheduleConsultHandler}>
          Let's Talk
        </Button>
      </div>
    </Drawer>

    // <SideBarContainer isOpen={isOpen} onClick={toggle}>
    //   <Icon onClick={toggle}>
    //     <CloseIcon />
    //   </Icon>
    //   <SideBarWrapper>
    //     <SideBarMenu>
    //       <SideBarLink to="home" onClick={toggle}>
    //         Home
    //       </SideBarLink>
    //       <SideBarLink to="about" onClick={toggle}>
    //         About
    //       </SideBarLink>
    //       <SideBarLink to="services" onClick={toggle}>
    //         Services
    //       </SideBarLink>
    //       <SideBarLink to="portfolio" onClick={toggle}>
    //         Portfolio
    //       </SideBarLink>
    //       {/* <SideBarLink to="portfolio" onClick={toggle}>Portfolio</SideBarLink> */}
    //       <SideBarLink to="contact_us" onClick={toggle}>
    //         Contact Us
    //       </SideBarLink>
    //     </SideBarMenu>
    //     <SideBtnWrap>
    //       <SideBarRoute onClick={scheduleConsultHandler}>
    //         Let's Talk
    //       </SideBarRoute>
    //     </SideBtnWrap>
    //   </SideBarWrapper>
    // </SideBarContainer>
  );
};

export default Sidebar;
