import React from "react";
import {
  ImgWrap,
  InfoContainer,
  InfoWrapper,
  Img,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
} from "./InfoElements";
import { Button } from "../ButtonElement";
import { info } from "./Data";
import InfoTile from "./InfoTile";
import "./InfoSection.css";

const InfoSection = () => {
  return (
    <div className="info-tiles">
      {info.map((section, idx) => {
        return <InfoTile section={section} idx={idx} />;
      })}
    </div>
  );
};

export default InfoSection;
