import styled from "styled-components"

export const ExpertiseContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: black;
`;
export const ExpertiseWrapper = styled.div`
max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
  padding: 60px 50px;


   @media screen and (max-width: 768px) {
    grid-gap: 5px;
    
  } 

  @media screen and (max-width: 768px) {
    grid-gap: 2px;
    
  } 
  @media screen and (max-width: 768px) {
    padding: 10px 20px;
  }  
`

export const ExpertiseTile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  max-width: 100px;
  padding: 30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

  @media screen and (max-width: 480px) {
    padding: 10px;
  }
`

export const ItemImage = styled.img`
  height: 100px;
  width: 100px;
  margin-bottom: 10px;
  
    
  @media screen and (max-width: 768px){
    height: 50px;
  width: 50px;
  }
  @media screen and (max-width: 480px){
    height: 50px;
  width: 50px;
  }
`

export const ItemHeader = styled.h3`
font-size: 16px;
font-family: 'Hind';
color: #F900BF;
text-transform: uppercase;

@media screen and (max-width: 480px){
  font-size: 12px;
}
`;