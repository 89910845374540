import React, { useEffect, useState } from "react";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  BookBtn,
  BookBtnLink,
  NavLogoWrapper,
  LogoImage,
} from "./NavBarElements";
import { FaBars } from "react-icons/fa";
import Logo from "../../images/logo.png";
import { Link } from "react-scroll";
import "./Navbar.css";
import { Button, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";


const sections = ["Home", "About", "Services", "Portfolio", "Contact"];
const Navbar = ({ toggle, active, setActive }) => {
  const scheduleConsultHandler = () => {
    window.open(
      "https://calendly.com/mo-mixingmos/phone-consultation?month=2024-10"
    );
  };

  // State to track the scroll direction
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0); // To store the last scroll position

  // Effect to handle the scroll event
  useEffect(() => {
    const handleScroll = () => {
      // Get the current scroll position
      const currentScrollY = window.scrollY;

      // Check if the user is scrolling down or up
      if (currentScrollY > lastScrollY) {
        setShowNavbar(false); // Hide navbar when scrolling down
      } else {
        setShowNavbar(true); // Show navbar when scrolling up
      }

      // Update last scroll position
      setLastScrollY(currentScrollY);
    };

    // Add event listener on scroll
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  return (
    <div
      className={`navbar ${showNavbar ? "visible" : "hidden"}`}
      style={{
        transition: "transform 0.3s ease",
        position: "fixed", // To make the navbar sticky
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1000, // Ensure
      }}
    >

      <div className="nav-content">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "",
            gap: "1rem",
            width: "100%",
          }}
        >
          <div className="menu-btn">
            <IconButton sx={{ background: "#141414" }} onClick={toggle}>
              <MenuIcon sx={{ color: "white" }} />
            </IconButton>
          </div>
          <Link to="home" duration={300} smooth activeClass="link active">
            <div className="logo-small">
              <img src={Logo} />
            </div>
          </Link>
        </div>
        <div className="nav-links">
          {sections.map((section) => {
            return (
              <Link
                activeClass="link active"
                to={section.toLocaleLowerCase()}
                smooth
                duration={300}
                spy={true}
              >
                {section}
              </Link>
            );
          })}
        </div>
        <div className="nav-btn">
          <Button variant="contained">Let's Talk</Button>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
