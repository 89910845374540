import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";

export const Nav = styled.nav`
  background: #000;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  font-size: 1rem;
  position: sticky;
  width: 100%;
  z-index: 10;

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 2;
  width: 100%;
  padding: 0 24px;
  max-width: 1500px;
`;

// //router link is used to navigate to seperate page
// export const NavLogo = styled(LinkR)`
//   color: #fff;
//   justify-self: flex-start;
//   cursor: pointer;
//   font-size: 1.5rem;
//   display: flex;
//   align-items: center;
//   margin-left: 24px;
//   font-weight: bold;
//   text-decoration: none;
// `;

export const NavLogoWrapper = styled.div`
  width: 200px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NavLogo = styled(LinkS)`
  justify-self: flex-start;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 24px;
  position: relative;
  top: -12px;
`;

export const LogoImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  position: relative;
  right: 50px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  height: 80px;
`;

export const NavLinks = styled(LinkS)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  &.active {
    border-bottom: 5px solid #4700d8;
  }
`;

export const BookBtn = styled.div`
  display: flex;
  align-items: center;
  font-family: "Hind";

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const LoginBtnLink = styled(LinkR)`
  font-family: "Hind";
  color: #fff;
  outline: none;
  border: none;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease ease-in-out;
    color: #4700d8;
  }
`;

export const BookBtnLink = styled.button`
  border-radius: 50px;
  font-size: 1rem;
  font-family: "Hind";
  background: #4700d8;
  color: #fff;
  white-space: nowrap;
  padding: 10px 22px;
  outline: none;
  border: none;
  transition: all 0.2s ease-in-out;
  height: 50px;

  &:hover {
    transition: all 0.2s ease ease-in-out;
    background: #fff;
    color: #4700d8;
  }
`;
