import React from "react";
import './Deliverable.css'
const Delivarable = ({ deliverable }) => {
  return (
    <div className="deliverable">
      {deliverable?.icon}
      <span>{deliverable?.label}</span>
    </div>
  );
};

export default Delivarable;
