import styled from 'styled-components'
import { FaIcons } from "react-icons/fa"

export const AboutContainer = styled.div`
width: 100%; 
position: relative;
display: grid;
grid-template-columns: 1fr 1fr;
@media screen and (max-width: 780px){
    grid-template-columns: 1fr;
}

`

export const ContactInfoWrapper = styled.div`
display: flex; 
flex-direction: column;
justify-content: center; 
align-items: center;
background: black;
width: 100%;
overflow: hidden;

`

export const ContactInfoH2 = styled.h2`
font-size: 2.5rem;
font-family: 'Hind';
color: #F900BF; 
`

export const ContactInfoP = styled.p`
color: #fff;
font-size: 20px;
max-width: 500px;
text-align: center;
padding: 0px 60px;

@media screen and (max-width: 768px) {
    font-size: 18px;
}

@media screen and (max-width: 480px) {
    font-size: 16px;
}

`

export const ContactTileWrapper = styled.div`
display: flex; 
flex-direction: column;
align-items: center;
justify-content: center;
padding: 60px;

`

export const ContactTile = styled.div`
display: flex; 
flex-direction: column;
justify-content: center ;
align-items: center ;
padding: 5px;
width: 300px;

&:hover {
    border: 3px solid #F900BF;
    border-radius: 50px;
    /* background-color: #F900BF; */

}
`

export const FaPhone = styled(FaIcons)`
color: #F900BF;
`

export const ContactIcon = styled.div`
`
export const ContactInformation = styled.p`
color: #fff;

`

export const ContactFormWrapper = styled.div`
background: white;
display: flex;
align-items: center;
justify-content: center;
height: 100%;
`


export const ContactForm = styled.form`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 100px 0px;
/* padding: 300px 0px; */

@media screen and (max-width: 480px) {
        padding: 30px;
    }

& input {
    width: 350px;
    height: 30px;
    margin-bottom: 30px;
    border-radius: 50px;
    border-color: #000;
    border-width: 2px;
    size: 14px;

    @media screen and (max-width: 480px) {
        width: 300px;
    }
    
}

& input.error {
    border-color: #ff1000;
    background-color: #ff8a82;
    
}


& textarea {
    height: 200px;
    width: 350px;
    border-style: solid;
    border-width: 2px;
    border-radius: 25px;
    border-color: #000;
    padding: 20px 2px;

    @media screen and (max-width: 480px) {
        width: 300px;
        
    }
}

& label {
    font-size: 12px;
    font-family: 'Hind';
}
`

export const SubmitButton = styled.button`
width: 125px; 
height: 60px;
border-radius: 50px;
font-size: 14px;
border: none;
background-color: black;
color: white;
display: flex; 
flex-direction: column;
align-items: center;
justify-content: center;
font-family: 'Hind';
margin-top: 20px;

&:hover {
background: #F900BF;
transition: all 0.2s ease-in-out;
}
`

export const ConfirmationMessage = styled.p`
font-size: 18px;
color: #F900BF; 
font-family: 'Hind';

`